import {call, put} from 'redux-saga/effects';
import {setVerifyLoading, setVerifyStatus} from "../../api/verify";
import {sendVerifyMail} from "../request/Verify";
import {setContactsError} from "../../api/contacts";

export function* handleSendVerifyMail(payload) {
    try {
        yield put(setVerifyLoading(true));
        const response = yield call(sendVerifyMail, payload.addressId, payload.apNumber);
        const {data, status} = response;
        yield put(setVerifyStatus(true));
        yield put(setContactsError(false));

    } catch (e) {
    }

    yield put(setVerifyLoading(false));
}
