import validate from '../../constants/Validator';
import {validateContactPersons} from "./ContactPersons";
import {firewallReplacement} from "../../constants/Helpers";

export const ADD_PLACE = 'ADD_PLACE'
export const UPDATE_PLACE = 'UPDATE_PLACE'
export const DELETE_PLACE = 'DELETE_PLACE'
export const SET_PLACE = 'SET_PLACE'
export const CHANGE_PRODUCTS = 'CHANGE_PRODUCTS'
export const DELETE_INDUSTRY = 'DELETE_INDUSTRY'
export const ADD_INDUSTRY = 'ADD_INDUSTRY'
export const CHANGE_CHECKBOX = 'CHANGE_CHECKBOX'
export const SET_PLACES_ERRORS = 'SET_PLACES_ERRORS'
export const SET_PLACE_ERRORS = 'SET_PLACE_ERRORS'
export const GET_PLACES = 'GET_PLACES'
export const SET_PLACES = 'SET_PLACES'

const constraints = {
    active: {
        type: 'boolean'
    },
    title: {
        firewallChars: true,
        length: {
            minimum: 2, message: "^Bitte geben Sie mindestens 2 Zeichen an."
        },
    },
    address: {
        firewallChars: true,
        length: {
            minimum: 1, message: "^Bitte geben Sie eine gültige Straße und Hausnummer an."
        },
        /*
        format: {
            pattern: "^([a-zA-ZäöüÄÖÜß.,])+\\s([a-zA-ZäöüÄÖÜß/]|[0-9])+$",
            flags: "i",
            message: "^Bitte geben Sie eine gültige Straße und Hausnummer an."
        }
         */
    },
    zipCode: {
        firewallChars: true,
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 9999,
            greaterThanOrEqualTo: 1000,
            message: '^Bitte geben Sie eine Postleitzahl zwischen 1000 und 9999 an'
        }
    },
    city: {
        firewallChars: true,
        length: {
            minimum: 1, message: "^Dieses Feld darf nicht Leer sein."
        },
    }, country: '', industry: {
        length: {
            minimum: 1, message: "^Bitte geben Sie mindestens 1 Kategorie an."
        },
    }, products: {
        length: {
            minimum: 1, message: "^Dieses Feld darf nicht Leer sein."
        },
    },
    website: {
        firewallChars: true,
        url: {
            allowLocal: true,
            allowDataUrl: false,
            message: "^Bitte geben Sie eine gültige Website mit http:// oder https:// an."
        }
    }, paymentProvider: {
        length: {
            minimum: 1, message: "^Bitte wählen Sie einen Zahlungsanbieter aus."
        },
    },
    merchantId: {
        firewallChars: true,
        length: {
            minimum: 1, message: "^Dieses Feld darf nicht Leer sein."
        },
    }, default: {
        type: 'boolean'
    },
}

export const addPlace = (data = undefined) => ({
    type: ADD_PLACE, data
});

export const getPlaces = () => ({
    type: GET_PLACES,
});


export const updatePlace = (i, data) => ({
    type: UPDATE_PLACE, i, data
});

export const deletePlace = (data) => ({
    type: DELETE_PLACE, data
});

export const changeActive = (i, data) => ({
    type: CHANGE_CHECKBOX, i, field: 'active', data
});

export const setTitle = (i, data) => ({
    type: SET_PLACE, i, field: 'title', data
});

export const setStatus = (i, data) => ({
    type: SET_PLACE, i, field: 'status', data
});

export const setAddress = (i, data) => ({
    type: SET_PLACE, i, field: 'address', data
});

export const setZipcode = (i, data) => ({
    type: SET_PLACE, i, field: 'zipCode', data
});
export const setCity = (i, data) => ({
    type: SET_PLACE, i, field: 'city', data
});
export const setCountry = (i, data) => ({
    type: SET_PLACE, i, field: 'country', data
});
export const addIndustry = (i, key, data) => ({
    type: ADD_INDUSTRY, i, key, field: 'industry', data
});

export const deleteIndustry = (i, key) => ({
    type: DELETE_INDUSTRY, i, key,
});
export const setProducts = (i, data) => ({
    type: CHANGE_PRODUCTS, i, field: 'products', data
});
export const setWebsite = (i, data) => ({
    type: SET_PLACE, i, field: 'website', data
});
export const setPaymentProvider = (i, data) => ({
    type: SET_PLACE, i, field: 'paymentProvider', data
});
export const setMerchantId = (i, data) => ({
    type: SET_PLACE, i, field: 'merchantId', data
});

export const setPlaces = (data) => ({
    type: SET_PLACES, data
});


export const setPlacesErrors = (data) => ({
    type: SET_PLACES_ERRORS, data,
});

export const setPlaceErrors = (i, data) => ({
    type: SET_PLACE_ERRORS, i, data,
});


export function validatePlaces(places) {

    let errors = [];
    let currentValidation = null;
    let errorCount = 0;


    places.forEach(function (v, k) {
        if (v.data.hasOwnProperty('website') && validate.isEmpty(v.data.website)) {
            delete v.data['website'];
        }

        if (v.data.hasOwnProperty('merchantId') && v.data.paymentProvider === 'na') {
            delete v.data['merchantId'];
        }

        currentValidation = validate({...v.data}, constraints);
        errors.push(currentValidation);
        if (currentValidation !== undefined) {
            errorCount++;
        }


    })

    if (errorCount === 0) {
        return undefined;
    }

    return errors;
}


const initialState = [{
    data: {
        id: null,
        active: true,
        title: '',
        address: '',
        zipCode: '',
        city: '',
        country: '',
        industry: [],
        products: [],
        website: '',
        paymentProvider: '',
        merchantId: '',
        default: true,
        status: 'saved'
    }, error: {
        active: false,
        title: false,
        address: false,
        zipCode: false,
        city: false,
        country: false,
        industry: false,
        products: false,
        website: false,
        paymentProvider: false,
        merchantId: false,
        default: false,
    }
}];


export default (state = initialState, action) => {

    const {data, i, field, key} = action;
    let validation = null;
    let newState = null;
    let validationObj = {[field]: data};
    let skipValidation = false;
    switch (action.type) {
        case ADD_PLACE:
            newState = JSON.parse(JSON.stringify(initialState[0]))
            if (data === undefined) {

                if (state.length >= 1) {
                    newState.data.default = false;
                }
                newState.data.status = 'new';
                state.push(newState);
                return [...state]
            }
            return [{...state, data}];
        case UPDATE_PLACE:
            state[i]['data'] = data;
            return [...state];
        case SET_PLACE:
            if (field === 'website' && validate.isEmpty(data)) {
                skipValidation = true;
            }

            if (skipValidation === false) {
                validation = validate(validationObj, constraints);
                state[i]["error"][field] = validation ? state[i]["error"][field] = validation[field].join(', ') : false;
            } else {
                state[i]["error"][field] = false;
            }

            state[i]['data'][field] = firewallReplacement(data);
            return [...state];
        case CHANGE_CHECKBOX:
            state[i].data[field] = !state[i].data[field];
            return [...state];

        case SET_PLACES:
            let locations = data.map(function (v, i) {
                let validateObj = [{data: v}]
                let validatedLocations = validatePlaces(validateObj);
                if (validatedLocations === undefined) {
                    return {data: v, error: {...state['error']}};
                }
                return {data: v, error: {...state['error'], ...validatedLocations[0]}};
            });


            state = locations;
            return [...state];
        case CHANGE_PRODUCTS:

            let products = state[i]['data'][field];

            if (products.includes(data)) {
                // remove existing tag
                products = products.filter(function (v, k) {
                    return v !== data;
                });
            } else {
                // add tag
                products.push(data);

            }

            validation = validate(validationObj, constraints);
            state[i]["error"][field] = validation ? state[i]["error"][field] = validation[field].join(', ') : false;
            state[i]['data'][field] = products;
            return [...state];
        case DELETE_PLACE:
            // delete by key
            newState = state.filter(function (v, k) {
                return k !== data;
            });

            // add empty entry if all where deleted
            if (newState.length === 0) {
                newState.push(initialState[0]);
            }
            return [...newState]
        case SET_PLACE_ERRORS:
            state[i].error = {...initialState[0].error, ...data[0]};
            return [...state];
        case SET_PLACES_ERRORS:
            state.forEach(function (v, i) {
                if (data === undefined) {
                    state[i].error = {...initialState.error};
                } else {
                    state[i].error = {...initialState.error, ...data[i]};
                }

            })
            return [...state];
        case ADD_INDUSTRY:
            validation = validate(validationObj, constraints);
            state[i]["error"][field] = validation ? state[i]["error"][field] = validation[field].join(', ') : false;
            state[i]['data']['industry'][key] = data;
            return [...state];
        case DELETE_INDUSTRY:
            delete state[i]['data']['industry'][key];
            let filtered = state[i]['data']['industry'].filter(Boolean);
            state[i]['data']['industry'] = filtered;
            return [...state];
        default:
            return state;
    }
}
