import validate from "../../constants/Validator";

export const CHANGE_AGREEMENT_CHECKBOX = 'CHANGE_AGREEMENT_CHECKBOX'
export const SET_AGREEMENT_ERRORS = 'SET_AGREEMENT_ERRORS'
export const CHANGE_AGREEMENT_MODAL_STATUS = 'CHANGE_AGREEMENT_MODAL_STATUS'
export const GET_VOUCHER_CONTRACT = 'GET_VOUCHER_CONTRACT'
export const GET_CHILDREN_CONTRACT = 'GET_CHILDREN_CONTRACT'
export const GET_CARD_CONTRACT = 'GET_CARD_CONTRACT'

const constraints = {
    contractConfirmation: {
        isTrue: {
            message: '^Die Vertragsbestätigung muss akzeptiert sein.',
        }
    }, marketing: {
        type: 'boolean',
    },
}

export const closeAgreementModal = () => ({
    type: CHANGE_AGREEMENT_MODAL_STATUS, status: false, title: ''
});

export const openAgreementModal = (data, title = 'Vertrag') => ({
    type: CHANGE_AGREEMENT_MODAL_STATUS, status: true, data, title
});


export const getVoucherContract = (data) => ({
    type: GET_VOUCHER_CONTRACT, data,
});

export const getChildrenContract = (data) => ({
    type: GET_CHILDREN_CONTRACT, data,
});

export const getCardContract = (data) => ({
    type: GET_CARD_CONTRACT, data,
});

export const changeContractConfirmation = () => ({
    type: CHANGE_AGREEMENT_CHECKBOX, field: 'contractConfirmation'
});


export const changeDataPrivacy = () => ({
    type: CHANGE_AGREEMENT_CHECKBOX, field: 'dataPrivacy'
});


export const changeMarketing = () => ({
    type: CHANGE_AGREEMENT_CHECKBOX, field: 'marketing'
});

export const setAgreementErrors = (data) => ({
    type: SET_AGREEMENT_ERRORS, data,
});


export function validateAgreement(data) {
    return validate({...data.data}, constraints);
}

const initialState = {
    data: {
        contractConfirmation: false,
        dataPrivacy: false,
        marketing: false,
        contract: false,
        modelOpen: false,
        content: '',
        title: '',
    }, error: {
        contractConfirmation: false, dataPrivacy: false, marketing: false, contract: false,
    }
}

export default (state = initialState, action) => {
    const {data, field, status, title} = action;
    let validation = null;
    let validationObj = {};
    switch (action.type) {
        case CHANGE_AGREEMENT_CHECKBOX:
            validationObj = {[field]: !state['data'][field]};
            validation = validate(validationObj, constraints);
            state["error"][field] = validation ? state["error"][field] = validation[field].join(', ') : false;
            state['data'][field] = !state['data'][field];
            return {...state};
        case SET_AGREEMENT_ERRORS:
            if (data === undefined) {
                state['error'] = false;
                return {...state}
            }
            state['error'] = {...state['error'], ...data};
            return {...state};

        case CHANGE_AGREEMENT_MODAL_STATUS:
            state['data']['modelOpen'] = status;
            state['data']['content'] = data;
            state['data']['title'] = title;
            return {...state};
        default:
            return state;
    }
}
