export const OPEN_PLACE_MODAL = 'OPEN_PLACE_MODAL'
export const CLOSE_PLACE_MODAL = 'CLOSE_PLACE_MODAL'
export const EDIT_PLACE_MODAL = 'EDIT_PLACE_MODAL'
export const NEW_PLACE_MODAL = 'NEW_PLACE_MODAL'
export const SAVE_PLACE_MODAL = 'SAVE_PLACE_MODAL'

export const openPlaceModal = (data) => ({
    type: OPEN_PLACE_MODAL, data
});

export const newPlace = () => ({
    type: NEW_PLACE_MODAL
});

export const editPlace = (data) => ({
    type: EDIT_PLACE_MODAL, data
});

export const closePlaceModal = () => ({
    type: CLOSE_PLACE_MODAL,
});

export const savePlaceModal = () => ({
    type: SAVE_PLACE_MODAL
});


const initialState = {
    open: false, data: {
        id: 0
    }
}

export default (state = initialState, action) => {
    const {data} = action;
    switch (action.type) {
        case OPEN_PLACE_MODAL:
            state.data.id = data;
            return {...state, open: true}
        case CLOSE_PLACE_MODAL:
            return {...state, open: false}
        case SAVE_PLACE_MODAL:
            return {...state, data, open: false}
        case NEW_PLACE_MODAL:
            return {...state, data, open: true}
        case EDIT_PLACE_MODAL:
            state.data.id = data;
            state.open = true
            return {...state}
        default:
            return state;
    }
}
