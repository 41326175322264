export function registerErrorHandlers() {

    function report(event) {
        // report error to graylog
        // console.log(event.message);
    }

    window.removeEventListener("error", report)
    window.addEventListener("error", report)
}
