import {call, put} from 'redux-saga/effects';
import {setVerifyLoading, setVerifyStatus} from "../../api/verify";
import {setContactsError} from "../../api/contacts";
import {getAffiliate} from "../request/Affiliate";
import {setAffiliate, setAffiliateStatus} from "../../api/affiliate";
import {getMaintenanceMode, setMaintenanceMode} from "../../api/maintenance";
import {getMaintenance} from "../request/Maintenance";


export function* handleGetMaintenance(payload) {
    try {
        const response = yield call(getMaintenance);
        const {data, status} = response;

        yield put(setMaintenanceMode(false));
    } catch (e) {
        yield put(setMaintenanceMode(true));
    }
}
