import React, {Suspense, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {Route, Routes} from 'react-router'
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {ThemeProvider} from '@mui/material/styles';

import {history, persistor, store} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react'
import axios from 'axios';
import './App.scss';
import {ReduxRouter} from '@lagunovsky/redux-react-router'
import {API_TOKEN, tagManagerArgs} from "./constants/Config";
import {registerErrorHandlers} from './Components/error/ReportError'
import {setBearerToken} from "./redux/api/token";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {setError} from "./redux/form/Error";
import LoadingPage from "./Components/Pages/LoadingPage";
import {setStep} from "./redux/form/Stepper";
import TagManager from 'react-gtm-module'
import {ReactComponent as CloseIcon} from './assets/img/close.svg';
import {getAffiliate} from "./redux/api/affiliate";
import {ContactPage, FinishPage, MaintenancePage, VerifyPage, WelcomePage} from "./Components/Pages/";
import {StyledFooterLi, StyledFooterUl, theme} from "./constants/Style";
import {getMaintenanceMode} from "./redux/api/maintenance";
import {useMediaQuery} from '@mui/material';
import MyAppBar from "./MyAppBar";

registerErrorHandlers();

const query = new URLSearchParams(window.location.search);
const affiliateCode = query.get('affiliatecode');


function App() {
    const dispatch = useDispatch();
    const bearerToken = useSelector((state) => state.token.token);
    const maintenance = useSelector((state) => state.maintenance.isMaintenanceMode);




    axios.defaults.headers.common['API-KEY'] = API_TOKEN;
    if (bearerToken) {
        axios.defaults.headers.common['Authorization'] = bearerToken;
    }

    useEffect(() => {

        dispatch(getMaintenanceMode());

        const affiliate = store.getState().affiliate;
        if (affiliateCode !== null && affiliate.code !== affiliateCode) {
            dispatch(getAffiliate(affiliateCode));
        } else if (affiliate.status === false) {
            dispatch(getAffiliate('default'));
        }

        TagManager.initialize(tagManagerArgs);

        // axios global config
        axios.defaults.headers.common['API-KEY'] = API_TOKEN;

        // get bearer token
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data

            if (response.headers.hasOwnProperty('authorization') && response.headers.authorization) {
                axios.defaults.headers.common['Authorization'] = response.headers.authorization;
                dispatch(setBearerToken(response.headers.authorization))
            }


            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error

            if (error.response === undefined) {
                dispatch(setError('Es ist ein Netzwerk Fehler aufgetreten bitte versuchen Sie es später erneut.'));
                return Promise.reject(error);
            }

            if (error.config.method) {

                switch (error.response.status) {
                    case 401:
                        dispatch(setError('Sie sind nicht berechtigt.'));
                        break;
                    case 400:
                        dispatch(setError('Es ist ein Fehler aufgetreten bitte überprüfen Sie Ihre Daten und versuchen Sie es später erneut.'));
                        break;
                    case 500:
                        dispatch(setError('Es ist ein unerwarteter Fehler aufgetreten bitte versuchen Sie es später erneut.'));
                        break;
                    default:
                        break;
                }
            }


            return Promise.reject(error);
        });
    });




    const CloseButton = ({closeToast}) => (<CloseIcon onClick={closeToast} style={{
        width: '28px', height: '28px', minWidth: '28px', marginTop: 'auto', marginBottom: 'auto'
    }}/>);


    return (

        <ThemeProvider theme={theme}>
            <PersistGate loading={<LoadingPage/>} persistor={persistor}>
                <CssBaseline/>
                {/* Header start */}
                <MyAppBar/>

                {/* Header end */}
                {/* Content start */}
                <Container id="main" component="main"
                           maxWidth={'xl'}
                           xs={12}
                           sx={{'mt': '24px'}}>
                    <Box minHeight={'720px'}>
                        <ToastContainer
                            closeButton={CloseButton}
                        />
                        <ReduxRouter history={history}> { /* place ConnectedRouter under Provider */}
                            <Suspense fallback={<LoadingPage/>}>
                                {maintenance === true && <MaintenancePage/>}
                                {maintenance === false && <Routes>
                                    <Route exact={false} path="/verify/:token" element={<VerifyPage/>}/>
                                    <Route path="/finish" element={<FinishPage/>}/>
                                    <Route path="/contact" element={<ContactPage/>}/>
                                    <Route path="/" element={<WelcomePage/>}/>
                                </Routes>}
                            </Suspense>
                        </ReduxRouter>
                    </Box>
                </Container>
                {/* Content end */}
                {/* Footer start */}
                <Container
                    sx={{
                        maxWidth: '100% !important',
                        marginTop: 'calc(1.6% + 72px)',
                        bottom: 0,
                        position: {
                            xs: 'relative',
                            sm: 'relative',
                            md: 'sticky'
                        },
                        zIndex: 10

                    }}
                    component="footer">
                    <Grid container sx={{maxWidth: '100%'}} margin={'auto'}>
                        <Grid xs={12} xl={11} item>
                            <StyledFooterUl disablePadding={true}>
                                <StyledFooterLi>
                                    <Typography variant="body" align="left">
                                        <Typography variant="caption" component='span'>
                                            <b> {'©'}
                                                <Link sx={{textDecoration: 'none'}} color="inherit"
                                                      target="_blank"
                                                      rel="noopener"
                                                      style={{fontSize: '12px'}}
                                                      href="https://www.pluxee.at/">
                                                    Pluxee
                                                </Link>{' '}
                                                {new Date().getFullYear()}
                                            </b>
                                        </Typography>
                                    </Typography>
                                </StyledFooterLi>
                                <StyledFooterLi>
                                    <Link sx={{textDecoration: 'none'}} target="_blank" rel="noopener"
                                          color="inherit"
                                          href="https://www.pluxee.at/ueber-uns/">
                                        <Typography variant="caption" component='span'>
                                            Über Pluxee
                                        </Typography>
                                    </Link>
                                </StyledFooterLi>
                                <StyledFooterLi>
                                    <Link sx={{textDecoration: 'none'}} target="_blank" rel="noopener"
                                          color="inherit"
                                          href="https://www.pluxee.at/verhaltenskodex/">
                                        <Typography variant="caption" component='span'>
                                            Verhaltenskodex
                                        </Typography>
                                    </Link>
                                </StyledFooterLi>
                                <StyledFooterLi>
                                    <Link sx={{textDecoration: 'none'}} target="_blank" rel="noopener"
                                          color="inherit"
                                          href="https://www.pluxee.at/impressum/">
                                        <Typography variant="caption" component='span'>
                                            Impressum
                                        </Typography>
                                    </Link>
                                </StyledFooterLi>
                                <StyledFooterLi>
                                    <Link sx={{textDecoration: 'none'}} target="_blank" rel="noopener"
                                          color="inherit"
                                          href="https://www.pluxee.at/datenschutz/">
                                        <Typography variant="caption" component='span'>
                                            Datenschutz
                                        </Typography>
                                    </Link>
                                </StyledFooterLi>
                                <StyledFooterLi>
                                    <Link sx={{textDecoration: 'none'}} target="_blank" rel="noopener"
                                          color="inherit"
                                          href="https://www.pluxee.at/cookie-richtlinie/">
                                        <Typography variant="caption" component='span'>
                                            Cookie Richtlinie
                                        </Typography>
                                    </Link>
                                </StyledFooterLi>
                                <StyledFooterLi>
                                    <Link id='cookie-settings' sx={{textDecoration: 'none'}}
                                          target="_self"
                                          rel="noopener"
                                          color="inherit"
                                          onClick={(e) => window.Optanon.ToggleInfoDisplay()}
                                          href="#cookie-settings">
                                        <Typography variant="caption" component='span'>
                                            Cookie Einstellungen
                                        </Typography>
                                    </Link>
                                </StyledFooterLi>
                            </StyledFooterUl>
                        </Grid>
                        <Grid xs={12} xl={1} item>
                            <StyledFooterUl>
                                <StyledFooterLi sx={{marginLeft: 'auto', marginRight: 'auto'}}>
                                    <Typography variant="caption" component='span'>
                                        Version 1.3
                                    </Typography>
                                </StyledFooterLi>
                            </StyledFooterUl>
                        </Grid>
                    </Grid>
                </Container>
                {/* Footer end */}
            </PersistGate>
        </ThemeProvider>);
}

export default App;
