import validate from "../../constants/Validator";


export const SET_AP_NUMBER = 'SET_AP_NUMBER'
export const SET_AP_NUMBER_ERROR = 'SET_AP_NUMBER_ERROR'
export const SET_AP_NUMBER_LOADING = 'SET_AP_NUMBER_LOADING'


export const setApNumber = (apNumber) => ({
    type: SET_AP_NUMBER, apNumber
});

export const setApNumberError = (error) => ({
    type: SET_AP_NUMBER_ERROR, error
});

export const setApNumberLoading = (loading) => ({
    type: SET_AP_NUMBER_LOADING, loading
});


const constraints = {
    apNumber: {
        format: {
            pattern: "^[3|4][0-9]{4}$", flags: "i", message: "^Bitte geben Sie eine gültige AP-Nummer an."
        }
    },
}


const initialState = {
    apNumber: '', error: '', loading: false
}

export default (state = initialState, action) => {
    const {data, status, apNumber, error, loading} = action;
    let validation = null;
    let validationObj = {};
    switch (action.type) {
        case SET_AP_NUMBER:
            validationObj = {['apNumber']: apNumber};
            validation = validate(validationObj, constraints);
            state["error"] = validation ? state["error"] = validation['apNumber'].join(', ') : false;
            state['apNumber'] = apNumber;
            return {...state};
        case SET_AP_NUMBER_ERROR:
            state['error'] = error;
            return {...state};
        case SET_AP_NUMBER_LOADING:
            state['loading'] = loading;
            return {...state};
        default:
            return state;
    }
}
