export const NEXT_STEP = 'NEXT_STEP'
export const PREVIOUS_STEP = 'PREVIOUS_STEP'
export const RESET_STEP = 'RESET_STEP'
export const SET_STEP = 'SET_STEP'

export const resetStep = () => ({
    type: RESET_STEP
});

export const setStep = (step) => ({
    type: SET_STEP,
    step
});

export const nextStep = () => ({
    type: NEXT_STEP
});

export const previousStep = () => ({
    type: PREVIOUS_STEP,
});

const initialState = {
    step: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_STEP:
            const {step} = action;
            return {...state, step}
        case RESET_STEP:
            return {...state, step: 0}
        case NEXT_STEP:
            return {...state, step: state.step + 1}
        case PREVIOUS_STEP:
            return {...state, step: state.step - 1}
        default:
            return state;
    }
}
