//https://www.youtube.com/watch?v=1K26DIKt3w8
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import createSagaMiddleware from "redux-saga";
import {watcherSaga} from "./sagas/rootSaga";
import companyReducer from './api/company';
import companyDataReducer from './form/CompanyData';
import serviceReducer from './form/Services';
import placesReducer from './form/Places';
import contactPersonReducer from './form/ContactPersons';
import agreementReducer from './form/Agreement';
import contactsReducer from './api/contacts';
import errorReducer from './form/Error';
import stepperReducer from './form/Stepper';
import apNumberReducer from './form/ApNumber';
import maintenanceReducer from './api/maintenance';
import formStatusReducer from './form/Form';
import palaceModalReducer from './form/PlaceModal';
import deleteReducer from './form/Delete';
import bearerTokenResucer from './api/token';
import affiliateReducer from './api/affiliate';
import verifyReducer from './api/verify';
import {createBrowserHistory} from "history";
import {createRouterMiddleware, createRouterReducer} from '@lagunovsky/redux-react-router'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage/session';

export const history = createBrowserHistory();

const reducers = combineReducers({
    router: createRouterReducer(history),
    company: companyReducer,
    companyData: companyDataReducer,
    step: stepperReducer,
    contacts: contactsReducer,
    token: bearerTokenResucer,
    error: errorReducer,
    verify: verifyReducer,
    palaceModal: palaceModalReducer,
    contactPersons: contactPersonReducer,
    services: serviceReducer,
    places: placesReducer,
    agreement: agreementReducer,
    apNumber: apNumberReducer,
    formStatus: formStatusReducer,
    delete: deleteReducer,
    affiliate: affiliateReducer,
    maintenance: maintenanceReducer
});


const persistConfig = {
    key: 'root', version: 1, storage, blacklist: ['router', 'verify', 'contacts', 'agreement', 'formStatus'], // What you don't want to persist
    whitelist: ['company', 'step', 'token', 'palaceModal', 'contactPersons', 'companyData', 'services', 'places', 'formStatus', 'delete', 'affiliate'] // What you want to persist
}

const persistedReducer = persistReducer(persistConfig, reducers)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export const store = createStore(persistedReducer, {},
    composeEnhancers(
        applyMiddleware(createRouterMiddleware(history), ...middlewares)
    )
);

sagaMiddleware.run(watcherSaga);

export const persistor = persistStore(store);

export default store;
