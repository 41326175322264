export const SAVE_FORM_DATA = 'SAVE_FORM_DATA'
export const GET_FORM_DATA = 'GET_FORM_DATA'
export const SET_FORM_DATA_STATUS = 'SET_FORM_DATA_STATUS'

export const getFormData = () => ({
    type: GET_FORM_DATA
});

export const saveFormData = (formData) => ({
    type: SAVE_FORM_DATA, formData
});

export const setFormDataStatus = (status) => ({
    type: SET_FORM_DATA_STATUS, status
});

const initialState = {
    status: false
}

export default (state = initialState, action) => {
    const {data, status} = action;
    switch (action.type) {
        case SET_FORM_DATA_STATUS:
            return {...state, status}
        default:
            return state;
    }
}
