import {call, put} from 'redux-saga/effects';
import {requestGetCardContract, requestGetChildrenContract, requestGetVoucherContract} from "../request/Agreement";
import {openAgreementModal} from "../../form/Agreement";

export function* handleRequestGetVoucherContract(action) {
    try {
        const response = yield call(requestGetVoucherContract, action.data);
        const {data} = response;
        yield put(openAgreementModal(data, 'Gutscheinvertrag'));
    } catch (e) {

    }
}

export function* handleRequestGetChildrenContract(action) {
    try {
        const response = yield call(requestGetChildrenContract, action.data);
        const {data} = response;
        yield put(openAgreementModal(data, 'Kinderbetreuungs Pass Vertrag'));
    } catch (e) {

    }
}

export function* handleRequestGetCardContract(action) {

    try {
        const response = yield call(requestGetCardContract, action.data);
        const {data} = response;
        yield put(openAgreementModal(data, 'Kartenvertrag'));
    } catch (e) {

    }
}




