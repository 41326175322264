import axios from 'axios';
import {API_URL} from '../../../constants/Config'

export function sendVerifyMail(addressId, apNumber) {
    return axios.request({
        method: 'post', url: API_URL + '/getVerificationCode', data: {
            affaddressid: addressId, affiliateid: apNumber
        }
    })
}
