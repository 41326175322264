export const GET_AFFILIATE = 'GET_AFFILIATE'
export const SET_AFFILIATE = 'SET_AFFILIATE'
export const SET_AFFILIATE_STATUS = 'SET_AFFILIATE_STATUS'

export const getAffiliate = (affiliateCode) => ({
    type: GET_AFFILIATE, affiliateCode
});

export const setAffiliate = (payload) => ({
    type: SET_AFFILIATE, payload
});

export const setAffiliateStatus = (status) => ({
    type: SET_AFFILIATE_STATUS, status
});


const initialState = {
    code: undefined,
    id: undefined,
    entry_fee: 35,
    service_fee: 5,
    status: false
}

export default (state = initialState, action) => {
    const {payload, status, loading} = action;
    switch (action.type) {
        case SET_AFFILIATE:
            return {...state, ...payload}
        case SET_AFFILIATE_STATUS:
            return {...state, status}
        default:
            return state;
    }
}
