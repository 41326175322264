import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import store from './redux/store';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');

if (rootElement) {
    const root = createRoot(rootElement);
    root.render(<React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>);
}

reportWebVitals();