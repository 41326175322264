export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL; // default backend url
export const API_TOKEN = process.env.REACT_APP_API_TOKEN;
export const API_URL = BACKEND_URL + '/api/v1'; // for versioning
export const TAG_MANAGER_ID = 'GTM-5F9BDKJ'; // for versioning
export const tagManagerArgs = {
    gtmId: TAG_MANAGER_ID
}


export const CONTACT_PERSON_TAGS = [
    {key: 'main', label: 'Hauptverantwortlich (nur eine Person)'},
    {key: 'invoice', label: 'Rechnung'},
    {key: 'contract', label: 'Vertrag'},
    {key: 'marketing', label: 'Marketing'},
]

export const INDUSTRY_CATEGORIES = ['Apotheke', 'Bäckerei', 'Bar/Pub/Bistro', 'Baumarkt', 'Beauty & Schmuck', 'Blumenhandlung', 'Buchhandlung/Papierwaren', 'Cafe/Kaffeehaus', 'Catering & Kantine', 'Elektronik', 'Fast Food/Imbiss', 'Fleischerei', 'Freizeit', 'Home & Living', 'Hotel', 'KFZ Reparatur/Zubehör', 'Lebensmittel', 'Mode & Schuhe', 'Online Bestell- Zustellservice', 'nuItem>', 'Optiker', 'Reise', 'Restaurant', 'Skigebiete/Bergbahn', 'Spielwaren', 'Sport', 'Tankstelle', 'Trafik', 'Wellness', 'Zoohandlung',];
export const PAYMENT_PROVIDERS = [{
    name: 'Adyen',
    value: 'adyen',
    helpText: 'Die Merchant ID ist 15-stellig und beginnt mit 49875xxxxxxxxxx.'
},
    {
        name: 'Card Complete',
        value: 'cardComplete',
        helpText: 'Sie finden die Merchant ID als „VU-Nr.” direkt auf einem Händlerbeleg. Sie ist 9-stellig und beginnt mit 100xxxxxx, 000xxxxxx oder 600xxxxxx.'
    },
    {
        name: 'Concardis',
        value: 'concardis',
        helpText: 'Sie finden die Merchant ID als „VU-Nr.” direkt auf einem Visa-Händlerbeleg. Sie ist 9-stellig und beginnt mit 14xxxxxxx.'
    },
    {
        name: 'First Data',
        value: 'firstData',
        helpText: 'Sie können die Merchant ID, genannt „Geschäftspartner-ID”, direkt bei First Data erfragen. Sie ist 9-stellig und beginnt mit 80xxxxxxxx.'
    },
    {
        name: 'Global Payments',
        value: 'globalPayments',
        helpText: 'Sie finden die Merchant ID als „Händler-ID“ direkt auf einem Händlerbeleg. Sie ist 10-stellig und beginnt mit 731xxxxxxx.'
    },
    {
        name: 'Hobex',
        value: 'hobex',
        helpText: ' Sie können die Merchant ID, genannt „Retailer-ID”, direkt bei Hobex erfragen. Sie ist 14-stellig und beginnt mit Dxxxxxxxxxxxxx oder 9-stellig und beginnt mit 35xxxxxxx.'
    },
    {
        name: 'Payone',
        value: 'payone',
        helpText: 'PayOne (ehem. Six): Sie finden die Merchant ID als „VU-Nr.“ auf einer Abrechnungsinformation unter „Details zur Abrechnung”. Die 9-stellige Nummer beginnt mit 24xxxxxxx, 25xxxxxxx oder 30xxxxxxx.'
    },
    {
        name: 'SumUP',
        value: 'sumup',
        helpText: ''
    },
    {
        name: 'Anderer',
        value: 'other',
        helpText: 'Ist Ihr Zahlungsdienstleister nicht aufgeführt, geben Sie uns im Feld "Merchant ID" bitte einfach den Namen Ihres Dienstleisters und die Merchant ID bekannt.'
    },
    {
        name: 'Keine Kartenzahlung möglich',
        value: 'na',
        helpText: 'Wenn keine Kartenzahlungen möglich sind, wird kein Vertrag für Karten erstellt.'
    },
];

