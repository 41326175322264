import axios from 'axios';
import {API_URL} from '../../../constants/Config'

export function requestGetVoucherContract(data) {
    return axios.request({
        method: 'post', url: API_URL + '/contract/paper/preview', data
    })
}

export function requestGetChildrenContract(data) {
    return axios.request({
        method: 'post', url: API_URL + '/contract/childcare/preview', data
    })
}

export function requestGetCardContract(data) {
    return axios.request({
        method: 'post', url: API_URL + '/contract/card/preview', data
    })
}

