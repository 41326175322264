export const GET_VERIFY_CODE = 'GET_VERIFY_CODE'
export const SET_VERIFY_STATUS = 'SET_VERIFY_STATUS'
export const SET_VERIFY_LOADING = 'SET_VERIFY_LOADING'


export const getVerifyCode = (addressId, apNumber) => ({
    type: GET_VERIFY_CODE, addressId, apNumber
});

export const setVerifyStatus = (status) => ({
    type: SET_VERIFY_STATUS, status
});

export const setVerifyLoading = (loading) => ({
    type: SET_VERIFY_LOADING, loading
});

const initialState = {
    status: undefined, loading: false
}

export default (state = initialState, action) => {
    const {status, loading} = action;
    switch (action.type) {
        case SET_VERIFY_STATUS:
            return {...state, status}
        case SET_VERIFY_LOADING:
            return {...state, loading}
        default:
            return state;
    }
}
