import {call, put} from 'redux-saga/effects';
import {setContacts, setContactsLoading} from "../../api/contacts";
import {requestGetContacts} from "../request/Contacts";
import {setError} from "../../form/Error";

export function* handleGetContacts(payload) {
    try {

        yield put(setContactsLoading(true));
        const response = yield call(requestGetContacts, payload.number, payload.number);
        const {data} = response;

        if (data.length === 0) {
            yield put(setError('Wir konnten keine Kontakte finden, bitte kontaktieren Sie uns unter 01/328 60 60 - 250 oder akzeptanzpartner.at@pluxee.com.'));
        }

        yield put(setContacts(data));

    } catch (e) {

    }
    yield put(setContactsLoading(false));
}
