export const GET_CONTACTS = 'GET_CONTACTS'
export const SET_CONTACTS = 'SET_CONTACTS'
export const SET_CONTACTS_ERROR = 'SET_CONTACTS_ERROR'
export const SET_CONTACTS_LOADING = 'SET_CONTACTS_LOADING'

export const getContacts = (number) => ({
    type: GET_CONTACTS, number: number
});

export const setContacts = (contacts) => ({
    type: SET_CONTACTS, contacts
});

export const setContactsError = (error) => ({
    type: SET_CONTACTS_ERROR, error
});

export const setContactsLoading = (loading) => ({
    type: SET_CONTACTS_LOADING, loading
});

const initialState = {
    contacts: undefined, error: undefined, loading: false,
}

export default (state = initialState, action) => {
    const {contacts, error, loading} = action;
    switch (action.type) {
        case SET_CONTACTS:
            return {...state, contacts}
        case SET_CONTACTS_ERROR:
            return {...state, error}
        case SET_CONTACTS_LOADING:
            return {...state, loading}
        default:
            return state;
    }
}
