export const GET_COMPANY = 'GET_COMPANY'
export const SET_COMPANY = 'SET_COMPANY'

export const getCompany = () => ({
    type: GET_COMPANY
});

export const setCompany = (company) => ({
    type: SET_COMPANY, company
});

const initialState = {
    company: undefined
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANY:
            const {company} = action;
            return {...state, company}
        default:
            return state;
    }
}
