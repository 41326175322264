import React from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";


export default function LoadingPage(props) {
    return (<Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{minHeight: '100vh'}}
    >
        <Grid item xs={3}>
            <CircularProgress color='secondary'/>
        </Grid>
        <Grid item xs={3}>
            {props.text &&
                <Typography component="span" variant="body2">
                    {props.text}
                </Typography>
            }
        </Grid>
        {/*
        {error && (
            <div>
                <br/>
                <span>{error.error}</span>
            </div>
        )}
         */}
    </Grid>)
}