import React from 'react';
import {AppBar, Toolbar, Grid, useMediaQuery, Box, Link, List} from '@mui/material';
import {ReactComponent as LargeLogo} from "./logo.svg";
import {ReactComponent as SmallLogo} from "./logoSmall.svg";
import {ReactComponent as LogoLeft} from "./logoLeft.svg";
import {setStep} from "./redux/form/Stepper";
import {history} from "./redux/store";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material/styles";
import {useTheme} from '@mui/material/styles';

export const StyledAppBar = styled(AppBar)(({theme}) => ({
    borderBottom: '1px solid ' + theme.palette.secondary.main
}));


export const LogoBox = styled(Box)(({theme}) => ({
    maxHeight: '80px', display: 'flex', alignItems: "center", backgroundColor: theme.palette.primary.dark, zIndex: -0,
}));

export const LogoBox2 = styled(Box)(({theme}) => ({
    flex: '0 0 260px', display: 'flex', alignItems: 'center', position: 'relative', height: '80px',

    backgroundColor: theme.palette.primary.dark, '&:after': {
        right: 0,
        top: 0,
        border: '80px solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderRightColor: ' #fff',
        backgroundColor: '#221C46',
    },


    [theme.breakpoints.up('md')]: {
        flex: '0 0 364px',
    }


}));
const MyAppBar = () => {

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();


    const getLogoLeft = () => {
        if (!smallScreen) {
            return <LogoLeft/>;
        }
    }


    const getLogo = () => {
        if (smallScreen) {
            return <SmallLogo style={{width: '40px', height: '40px', 'margin-left': '20px'}}/>;
        } else {
            return <LargeLogo style={{width: '205px', height: '40px'}}/>;
        }
    }
    const handleOnClickLogo = (e) => {
        dispatch(setStep(0));
        history.push('/');
    }

    return (<StyledAppBar
            color="white"
            position="sticky"
            component="section"
        >
            <Toolbar disableGutters sx={{overflow: 'hidden'}}>
                <LogoBox>
                    <Box sx={{maxHeight: '80px'}}>
                        {getLogoLeft()}
                    </Box>
                    <LogoBox2>
                        <Link sx={{padding: 0}} color="inherit" href='#' onClick={handleOnClickLogo}>
                            {getLogo()}
                        </Link>
                    </LogoBox2>
                </LogoBox>
            </Toolbar>
        </StyledAppBar>);
};

export default MyAppBar;