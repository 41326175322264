export const GET_BEARER_TOKEN = 'GET_BEARER_TOKEN'
export const SET_BEARER_TOKEN = 'SET_BEARER_TOKEN'


export const getBearerToken = () => ({
    type: GET_BEARER_TOKEN
});
export const setBearerToken = (token) => ({
    type: SET_BEARER_TOKEN, token
});

const initialState = {
    token: undefined
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_BEARER_TOKEN:
            const {token} = action;
            return {...state, token}
        default:
            return state;
    }
}
