// define theme
import {createTheme, styled} from "@mui/material/styles";
import {List, ListItem} from "@mui/material";
import checkedImage from './../assets/img/checked.svg'; // Adjust path to your image file


export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 375,
            md: 768,
            lg: 960,
            xl: 1280,
        },
    },
    palette: {
        ':root': {
            '--swiper-theme-color': '#FF7375',
        },
        error: {
            main: '#b30000' // put your color here
        },
        primary: {
            lighter: "#1b51dc",
            light: "#283897",
            main: "#283897",
            dark: "#221C46",
            contrastText: "#fff",
        },
        secondary: {
            main: "#00EB5E",
            light: "#DAFCDB",
            dark: "#012F0D",
            contrastText: "#fff",
            coral: '#FF7375',
            violet: '#283897',
            violetLight: '#E1E2F8',
            aquamarine: '#3CDCC8',
            lime: '#FDEEC1',
            salmon: '#FF7375',
            fuchsia: '#E9003F',
        },
        neutrals: {
            lightViolet: '#E1E2F8',
            oldGrey: '#EAE9EF',
            lightGrey: '#efefef',
            mediumGrey: '#EFF1F6',
            tableGrey: '#F8F7F9',
        },

        mainBlue: {
            20: '#DEF3FB',
            40: '#88ddfb',
            60: '#0fa4c9',
            80: '#06637A',
        },
        aquamarine: '#3CDCC8',
        white: "#fff",
        black: "#000",
        text: {
            primary: "#221C46"
        }
    },
    components: {
        MuiSwitch: {
            styleOverrides: {
                thumb: ({ownerState, theme}) => ({
                    ...(ownerState.checked === true && {
                        backgroundColor: theme.palette.secondary.main,
                    }),
                }),
                track: ({ownerState, theme}) => ({
                    ...(ownerState.checked === true && {
                        backgroundColor: theme.palette.secondary.main + '!important',
                    }),
                }),
            }
        },
        MuiButton: {
            styleOverrides: {

                root: {
                    borderRadius: 0,
                },
                containedSecondary: {  // targeting containedSecondary variant
                    border: '3px solid #221C46',
                    color: '#221C46',
                    '&:hover': {
                        backgroundColor: '#C7FCCA',
                        boxShadow: '4px 4px 0px rgba(34,28,70,0.2)', // adding drop shadow
                    },
                },
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '10px',
                    margin: '10px',
                    display: 'flex',
                    float: 'left',
                    marginRight: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    verticalAlign: 'top',
                    userSelect: 'none',
                    flexShrink: 0,
                    width: '1.5rem',
                    height: '1.5rem',
                    transitionProperty: 'box-shadow',
                    transitionDuration: '.15s',
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderRadius: 0,
                    borderColor: '#908c99',
                    color: '#FFFFFF',
                    backgroundColor: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#def3fb',
                        borderColor: '#1b51dc',
                    },
                    '&.Mui-checked': {
                        '&::before': {
                            content: '""',
                            display: 'inline-block',
                            position: 'absolute',
                            width: '15px',
                            height: '14px',
                            background: `url(${checkedImage}) no-repeat`,
                            backgroundSize: 'cover',
                        },
                    },
                    '& svg': {
                        display: 'none',
                    },

                },
            },

        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1B51DC',
                    },
                    '&:hover': {
                        borderColor: '#526CF8',
                        backgroundColor: '#DEF3FB',
                    },
                    '&:hover .MuiSelect-select': {
                        backgroundColor: '#DEF3FB',
                        color: 'black'
                    },
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: 80,
                    minHeight: 80
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#221C46',
                    fontFamily: "TT Travels",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "18px",
                    borderRadius: "0px",
                    padding: 16,
                    boxShadow: '4px 4px 0px rgba(34,28,70,0.2)', // adding drop shadow
                },
                arrow: {
                    '&::before': {
                        backgroundColor: '#221C46',
                    },
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: ({ownerState, theme}) => ({
                    maxWidth: '351px',
                    padding: '0px !important',
                    [theme.breakpoints.up('md')]: {
                        maxWidth: '704px',
                    }, [theme.breakpoints.up('lg')]: {
                        maxWidth: '896px',
                    },
                    [theme.breakpoints.up('xl')]: {
                        maxWidth: '1153px',
                    },
                }),
            }

        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    top: 'calc(40% - .5em)',  // adjust this as needed
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: 'TT Travels',
                    fontSize: '16px',
                    color: '#1B51DC !important',
                    textDecoration: 'underline',
                    textUnderlineOffset: '5px',
                    textDecorationThickness: '2px',
                    paddingBottom: '5px',

                    '&:hover': {
                        textDecoration: 'none',
                    },
                    '&:focus': {
                        outline: '1px solid #1B51DC',  // Add your focus border color here
                    },
                },
            }
        }

    },
    typography: {
        fontFamily: 'TT Travels, Arial',
        color: '#221C46',
        h1: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "42px",
            lineHeight: "56px"
        },
        h2: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "42px",
            lineHeight: "56px"
        },
        h3: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "32px",
            lineHeight: "44px"
        },

        h4: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "28px",
            lineHeight: "32px"
        },
        h5: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "32px"
        },
        h6: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "32px",
            color: '#221C46',
        },
        subtitle1: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "28px"
        }, subtitle2: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "18px"
        },
        body1: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px"
        },
        body2: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "20px"
        },
        caption: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "18px"
        },
        captionBold: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "12px",
            lineHeight: "18px"
        },
        overline: {
            fontFamily: "TT Travels",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "10px",
            lineHeight: "16px"
        }
    },
});


export const StyledFooterUl = styled(List)(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    flexWrap: 'wrap',
    color: theme.palette.primary.dark,
    [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
    },
}));

export const StyledFooterLi = styled(ListItem)(({theme}) => ({
    '&:before': {
        content: "'|'",
        marginRight: '16px'
    },
    '&:first-of-type': {
        '&:before': {
            display: 'none',
        },
    },
    width: 'auto !important',
    justifyContent: 'center !important',
    whiteSpace: 'noWrap',
    [theme.breakpoints.up('lg')]: {
        justifyContent: 'flex-start !important',
        width: 'auto !important',
    },

    'a': {
        color: theme.palette.primary.dark + '!important',
    }
}));