// @see Documentation https://validatejs.org/
import {PhoneNumberUtil} from 'google-libphonenumber';

const validate = require("validate.js");
const ibantools = require('ibantools');
const phoneUtil = PhoneNumberUtil.getInstance();


validate.validators.iban = function (value, options, key, attributes) {
    if (attributes['iban'] !== undefined) {
        let iban = ibantools.electronicFormatIBAN(value);
        if (!ibantools.isValidIBAN(iban)) {
            return [options.message];
        }
    }
    return undefined;
}

validate.validators.bic = function (value, options, key, attributes) {
    if (attributes['bic'] !== undefined) {
        if (!ibantools.isValidBIC(value)) {
            return [options.message];
        }
    }
    return undefined;
}

validate.validators.isTrue = function (value, options, key, attributes) {
    if (attributes[key] !== undefined) {
        if (value !== true) {
            return [options.message];
        }
    }
    return undefined;
}

validate.validators.austriaPhoneNumber = function (value, options, key, attributes) {
    if (attributes[key] !== undefined) {
        try {
            let number = phoneUtil.parse(value, 'AT');
            if (!phoneUtil.isPossibleNumber(number) || !phoneUtil.isValidNumberForRegion(number, 'AT')) {
                return [options.message];
            }
        } catch (NumberParseException) {
            return [options.message];
        }
    }
    return undefined;
}

validate.validators.firewallChars = function (value, options, key, attributes) {
    if (attributes[key] !== undefined) {


        let error = [];
        if (value.includes('"')) {
            error.push('"');
        }

        if (value.includes("'")) {
            error.push("'");
        }

        if (value.includes("`")) {
            error.push("`");
        }

        if (value.includes(";")) {
            error.push(";");
        }

        if (error.length > 0) {

            switch (error.length) {
                case 1:
                    return ['^Das Zeichen ' + error[0] + ' ist nicht erlaubt!'];
                default:
                    return ['^Diese Zeichen ' + error.join(' ') + ' sind nicht erlaubt!'];
            }
        }


    }
    return undefined;
}

export default validate;