import {call, put} from 'redux-saga/effects';
import {getAffiliate} from "../request/Affiliate";
import {setAffiliate, setAffiliateStatus} from "../../api/affiliate";


export function* handleGetAffiliate(payload) {
    try {
        const response = yield call(getAffiliate, payload.affiliateCode);
        const {data, status} = response;
        yield put(setAffiliate(data));
        yield put(setAffiliateStatus(true));
    } catch (e) {
    }
}
