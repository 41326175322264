export const SET_DELETE = 'SET_DELETE'


export const setDeleteLocation = (data) => ({
    type: SET_DELETE, field: 'locationIds', data
});
export const setDeleteContactPerson = (data) => ({
    type: SET_DELETE, field: 'contactIds', data
});

const initialState = {
    contactIds: [], locationIds: []
}

export default (state = initialState, action) => {

    const {field, data} = action;
    switch (action.type) {
        case SET_DELETE:
            state[field].push(data);
            return {...state}
        default:
            return state;
    }
}
