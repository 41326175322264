export const GET_MAINTENANCE_MODE  = 'GET_MAINTENANCE_MODE'
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE'

export const getMaintenanceMode = () => ({
    type: GET_MAINTENANCE_MODE,
});

export const setMaintenanceMode = (isMaintenanceMode) => ({
    type: SET_MAINTENANCE_MODE,
    isMaintenanceMode
});

const initialState = {
    isMaintenanceMode: false,
}

export default (state = initialState, action) => {
    const {isMaintenanceMode} = action;
    switch (action.type) {
        case SET_MAINTENANCE_MODE:
            return {...state, isMaintenanceMode}
        default:
            return state;
    }
}
