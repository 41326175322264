import {toast} from 'react-toastify';
import {ReactComponent as InfoIcon} from "../../assets/img/info-icon.svg";
import React from "react";


export const GET_ERROR = 'GET_ERROR'
export const SET_ERROR = 'SET_ERROR'
export const SET_COMPANY_DATA_ERROR = 'SET_COMPANY_DATA_ERROR'

export const getError = () => ({
    type: GET_ERROR
});

export const setError = (error) => ({
    type: SET_ERROR, error
});

export const setCompanyDataError = (error) => ({
    type: SET_COMPANY_DATA_ERROR, error
});

const initialState = {
    error: null,

    companyData: {
        companyName: false,
        businessName: false,
        street: false,
        zipCode: false,
        city: false,
        uid: false,
        phone: false,
        email: false,
        paymentRecipient: false,
        iban: false,
        bic: false,
    }, contactPersons: {
        salutation: false, firstname: false, lastname: false, email: false, tags: false,
    }

}


export default (state = initialState, action) => {

    const {error} = action;
    switch (action.type) {
        case SET_ERROR:
            toast.error(error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                icon: <InfoIcon className={'info-toast-error'}/>,
            });
            return {...state, error: undefined}
        case SET_COMPANY_DATA_ERROR:


            state['companyData']['bic'] = true;
            return state
        default:
            return state;
    }
}
