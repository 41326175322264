import {call, put} from 'redux-saga/effects';
import {getServices} from "../../form/Services";
import {setProducts} from "../../form/Places";


export function* handleGetServices() {
    try {
        const response = yield call(getServices);
        const {data} = response;
        yield put(setProducts(data));
    } catch (e) {

    }
}
