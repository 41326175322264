import validate from '../../constants/Validator';
import {firewallReplacement} from "../../constants/Helpers";

export const ADD_CONTACT_PERSON = 'ADD_CONTACT_PERSON'
export const GET_CONTACT_PERSON = 'GET_CONTACT_PERSON'
export const DELETE_CONTACT_PERSON = 'DELETE_CONTACT_PERSON'
export const SET_CONTACT_PERSON_FIELD = 'SET_CONTACT_PERSON_FIELD'
export const SET_CONTACT_PERSONS = 'SET_CONTACT_PERSONS'
export const CHANGE_CONTACT_TAG = 'CHANGE_CONTACT_TAG'
export const SET_CONTACT_ERRORS = 'SET_CONTACT_ERRORS'

const constraints = {
    salutation: {
        firewallChars: true,
        length: {
            minimum: 1,
            message: "^Bitte wählen Sie eine Anrede aus."
        },
        format: {
            pattern: "^H|F$",
            flags: "i",
            message: "^Bitte wählen Sie eine Anrede aus."
        }
    },
    firstname: {
        firewallChars: true,
        length: {
            minimum: 1,
            message: "^Dieses Feld darf nicht Leer sein."
        },
    },
    lastname: {
        firewallChars: true,
        length: {
            minimum: 1,
            message: "^Dieses Feld darf nicht Leer sein."
        },
    },
    email: {
        firewallChars: true,
        email: {
            message: "^Bitte geben Sie eine gültige E-Mail Adresse an."
        }
    },
    tags: {
        firewallChars: true,
        length: {
            minimum: 1,
            message: "^Bitte geben Sie mindestens 1 Zuständigkeit an."
        },
    },
}


export const getContactPerson = () => ({
    type: GET_CONTACT_PERSON,
});

export const addContactPerson = (data) => ({
    type: ADD_CONTACT_PERSON,
    data
});

export const deleteContactPerson = (data) => ({
    type: DELETE_CONTACT_PERSON,
    data
});

export const setContactSalutation = (i, data) => ({
    type: SET_CONTACT_PERSON_FIELD,
    i,
    field: 'salutation',
    data
});

export const setContacts = (data) => ({
    type: SET_CONTACT_PERSONS,
    data
});

export const setContactFirstname = (i, data) => ({
    type: SET_CONTACT_PERSON_FIELD,
    i,
    field: 'firstname',
    data
});

export const setContactLastname = (i, data) => ({
    type: SET_CONTACT_PERSON_FIELD,
    i,
    field: 'lastname',
    data
});

export const setContactEmail = (i, data) => ({
    type: SET_CONTACT_PERSON_FIELD,
    i,
    field: 'email',
    data
});


export const changeContactTag = (i, data) => ({
    type: CHANGE_CONTACT_TAG,
    i,
    data
});

export const setContactPeronsErrors = (data) => ({
    type: SET_CONTACT_ERRORS,
    data,
});


export function validateContactPersons(contacts) {

    let errors = [];
    let currentValidation = null;
    let countMainTags = 0;
    let errorCount = 0;


    contacts.forEach(function (v, k) {
        countMainTags += v.data.tags.includes('main') ? 1 : 0;
    })


    contacts.forEach(function (v, k) {
        currentValidation = validate({...v.data}, constraints);

        if (countMainTags === 0) {
            if (currentValidation === undefined) {
                currentValidation = [];
            }
            currentValidation['tags'] = ['Mindestens 1 Person muss als Hauptkontakt angegeben werden.'];
        } else if (countMainTags > 1) {
            if (currentValidation === undefined) {
                currentValidation = [];
            }
            currentValidation['tags'] = ['Es darf nur 1 Person als Hauptkontakt angegeben werden.'];
        }

        errors.push(currentValidation);

        if (currentValidation !== undefined) {
            errorCount++;
        }
    })

    if (errorCount === 0) {
        return undefined;
    }

    return errors;
}

const errorObj = {
    error: {
        salutation: false,
        firstname: false,
        lastname: false,
        email: false,
        tags: false,
    }
}

const initialState = [{
    data: {
        id: '',
        salutation: '',
        firstname: '',
        lastname: '',
        email: '',
        tags: [],
    },
    ...errorObj
}];

export default (state = initialState, action) => {

    const {data, i, field} = action;
    let validation = null;
    let validatedContacts = null;
    let copyInitialState = JSON.parse(JSON.stringify(initialState[0]));


    switch (action.type) {
        case SET_CONTACT_PERSONS:
            state = data.map(function (v, i) {
                return {data: v, error: {...state['error']}};
            });

            validatedContacts = validateContactPersons(state)

            state.forEach(function (v, i) {
                if (validatedContacts !== undefined) {
                    state[i].error = {...errorObj.error, ...validatedContacts[i]};
                }
            })


            return [...state];
        case ADD_CONTACT_PERSON:
            if (data === undefined) {
                state.push(copyInitialState);
                return [...state]
            }

            return [{...state, data}];
        case SET_CONTACT_PERSON_FIELD:
            let validationObj = {[field]: data};
            validation = validate(validationObj, constraints);
            state[i]["error"][field] = validation ? state[i]["error"][field] = validation[field].join(', ') : false;
            state[i]['data'][field] = firewallReplacement(data);
            return [...state];

        case CHANGE_CONTACT_TAG:
            let tags = state[i]['data']['tags'];

            if (tags.includes(data)) {
                // remove existing tag
                tags = tags.filter(function (v, k) {
                    return v !== data;
                });
            } else {
                // add tag
                tags.push(data);
            }

            state[i]['data']['tags'] = tags;
            validatedContacts = validateContactPersons(state)
            if (validatedContacts !== undefined) {
                state.forEach(function (v, i) {

                    if (validatedContacts[i] === undefined) {
                        state[i].error = {...errorObj.error};
                    } else {
                        if (validatedContacts[i].hasOwnProperty('tags')) {
                            state[i].error = {...errorObj.error, tags: validatedContacts[i]['tags']};
                        } else {
                            state[i].error = {...errorObj.error};
                        }
                    }

                })
            }

            return [...state];
        case DELETE_CONTACT_PERSON:
            // delete by key
            let newState = state.filter(function (v, k) {
                return k !== data;
            });

            // add empty entry if all where deleted
            if (newState.length === 0) {
                newState.push(copyInitialState);
            }

            validatedContacts = validateContactPersons(newState);
            newState.forEach(function (v, i) {
                if (validatedContacts !== undefined) {
                    state[i].error = {...errorObj.error, ...validatedContacts[i]};
                }
            })

            return [...newState]
        case SET_CONTACT_ERRORS:
            state.forEach(function (v, i) {
                state[i].error = {...errorObj.error, ...data[i]};
            })
            return [...state];

        default:
            return state;
    }
}
