import validate from '../../constants/Validator';
import {firewallReplacement} from "../../constants/Helpers";

export const SET_COMPANY_DATA_FIELD = 'SET_COMPANY_DATA_FIELD'
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA'
export const SET_COMPANY_ERRORS = 'SET_COMPANY_ERRORS'
export const SET_COMPANY_TYPE = 'SET_COMPANY_TYPE'
export const GET_COMPANY_DATA = 'GET_COMPANY_DATA'
export const GET_ALL_COMPANY_DATA = 'GET_ALL_COMPANY_DATA'


const constraints = {
    companyName: {
        firewallChars: true,
        length: {
            minimum: 1,
            message: "^Dieses Feld darf nicht Leer sein."
        },
    },
    businessName: {
        firewallChars: true,
        length: {
            minimum: 1,
            message: "^Dieses Feld darf nicht Leer sein."
        },
    },
    street: {
        firewallChars: true,
        length: {
            minimum: 1,
            message: "^Bitte geben Sie eine gültige Straße und Hausnummer an."
        },
        /*
        format: {
            pattern: "^([a-zA-ZäöüÄÖÜß.,])+\\s([a-zA-ZäöüÄÖÜß/]|[0-9])+$",
            flags: "i",
            message: "^Bitte geben Sie eine gültige Straße und Hausnummer an."
        }
         */
    },
    zipCode: {
        firewallChars: true,
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 9999,
            greaterThanOrEqualTo: 1000,
            message: '^Bitte geben Sie eine Postleitzahl zwischen 1000 und 9999 an'
        }
    },
    city: {
        firewallChars: true,
        length: {
            minimum: 1,
            message: "^Dieses Feld darf nicht Leer sein."
        },
    },
    uid: {
        firewallChars: true,
        format: {
            pattern: "^ATU[0-9]{8}$|^[0-9]+$",
            flags: "i",
            message: "^Bitte geben Sie eine gültige UID mit ATU oder ZVR an."
        }
    },
    phone: {
        firewallChars: true,
        austriaPhoneNumber: {
            message: "^Bitte geben Sie eine Österreichische Telefonnummer an."
        },
    },
    email: {
        firewallChars: true,
        email: {
            message: "^Bitte geben Sie eine gültige E-Mail Adresse an."
        }
    },
    paymentRecipient: {
        firewallChars: true,
        length: {
            minimum: 1,
            message: "^Dieses Feld darf nicht Leer sein."
        },
    },
    iban: {
        firewallChars: true,
        iban: {
            message: 'Bitte geben Sie einen gültigen IBAN an.'
        }
    },
    bic: {
        firewallChars: true,
        bic: {
            message: 'Bitte geben Sie einen gültigen BIC an.'
        }
    },
    gisa: {
        firewallChars: true,
        format: {
            pattern: "^(\\s*|[0-9]+)$",
            flags: "i",
            message: "^Bitte geben Sie eine gültige Zahl an."
        }
    },
    website: {
        firewallChars: true,
        url: {
            allowLocal: true,
            allowDataUrl: false,
            message: "^Bitte geben Sie eine gültige Website mit http:// oder https:// an."
        },
    },
}


export const setCompanyErrors = (data) => ({
    type: SET_COMPANY_ERRORS,
    data,
});

export const setCompanyType = (data) => ({
    type: SET_COMPANY_TYPE,
    data,
});

export const setCompanyDataField = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data
});
export const setCompanyData = (data) => ({
    type: SET_COMPANY_DATA,
    data
});


export const getCompanyData = () => ({
    type: GET_COMPANY_DATA,
});

export const setCompanyBic = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'bic',
});

export const setCompanyBusinessName = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'businessName',
});

export const setCompanyCity = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'city',
});

export const setCompanyEmail = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'email',
});

export const setCompanyIban = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'iban',
});


export const setCompanyPaymentRecipient = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'paymentRecipient',
});

export const setCompanyUid = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'uid',
});

export const setCompanyZipCode = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'zipCode',
});

export const setCompanyStreet = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'street',
});

export const setCompanyPhone = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'phone',
});

export const setCompanyName = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'companyName',
});

export const setGisaNumber = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data: data,
    field: 'gisa',
});
export const setWebsite = (data) => ({
    type: SET_COMPANY_DATA_FIELD,
    data,
    field: 'website',
});


export function validateCompanyData(data) {

    if (validate.isEmpty(data.website)) {
        delete data['website'];
    }

    let validation = validate({...data}, constraints)

    if (data.hasOwnProperty('website') && validate.isEmpty(data.website)) {
        delete validation['website'];
    }

    return validation;
}

const initialState = {
    data: {
        companyType: 'exist',
        companyName: '',
        businessName: '',
        street: '',
        zipCode: '',
        city: '',
        uid: '',
        phone: '',
        email: '',
        paymentRecipient: '',
        iban: '',
        bic: '',
        gisa: '',
        website: '',
    },
    error: {
        companyName: false,
        businessName: false,
        street: false,
        zipCode: false,
        city: false,
        uid: false,
        phone: false,
        email: false,
        paymentRecipient: false,
        iban: false,
        bic: false,
        gisa: false,
        website: false,
    }
}


export default (state = initialState, action) => {
    let {data, field} = action;
    let skipValidation = false;
    let validation = null;

    switch (action.type) {
        case SET_COMPANY_DATA:
            let validatedCompany = validateCompanyData(data);
            state['error'] = {...state['error'], ...validatedCompany};
            state['data'] = data;
            return {...state};
        case SET_COMPANY_DATA_FIELD:
            if (field === 'website' && validate.isEmpty(data)) {
                skipValidation = true;
            }

            if (skipValidation === false) {
                let validationObj = {[field]: data};
                validation = validate(validationObj, constraints);
                state["error"][field] = validation ? state["error"][field] = validation[field].join(', ') : false;
            } else {
                state["error"][field] = false;
            }

            state['data'][field] = firewallReplacement(data);
            return {...state};

        case SET_COMPANY_ERRORS:
            state['error'] = {...state['error'], ...data};
            return {...state};
        case SET_COMPANY_TYPE:
            state['data']['companyType'] = data;
            return {...state};
        default:
            return state;
    }
}
