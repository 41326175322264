import {call, put} from 'redux-saga/effects';
import {requestGetContactPersons} from "../request/ContactPerson";
import {setContacts} from "../../form/ContactPersons";

export function* handleGetContactPerson(action) {
    try {
        const response = yield call(requestGetContactPersons);
        const {data} = response;
        yield put(setContacts(data));
    } catch (e) {

    }
}
