import axios from 'axios';
import {API_URL} from '../../../constants/Config'

export function requestSaveFormData(data) {
    return axios.request({
        method: 'post',
        url: API_URL + '/save',
        data
    })
}
