import {takeLatest,takeEvery} from 'redux-saga/effects';
import {handleGetCompanyData} from "./handlers/Company";
import {handleGetFormData, handleSaveForm} from "./handlers/Form";
import {GET_COMPANY_DATA} from '../form/CompanyData'
import {handleGetContacts} from "./handlers/Contacts";
import {GET_CONTACTS} from "../api/contacts";
import {GET_CARD_CONTRACT, GET_CHILDREN_CONTRACT, GET_VOUCHER_CONTRACT} from "../form/Agreement";
import {GET_VERIFY_CODE} from "../api/verify";
import {handleSendVerifyMail} from "./handlers/Verify";
import {GET_CONTACT_PERSON} from "../form/ContactPersons";
import {handleGetContactPerson} from "./handlers/ContactPersons";
import {
    handleRequestGetCardContract,
    handleRequestGetChildrenContract,
    handleRequestGetVoucherContract
} from "./handlers/Agreement";
import {GET_FORM_DATA, SAVE_FORM_DATA} from "../form/Form";
import {GET_SERVICES} from "../form/Services";
import {handleGetServices} from "./handlers/Services";
import {handleGetAffiliate} from "./handlers/Affiliate";
import {GET_AFFILIATE} from "../api/affiliate";
import {GET_MAINTENANCE_MODE} from "../api/maintenance";
import {handleGetMaintenance} from "./handlers/Maintenance";

export function* watcherSaga() {
    yield takeLatest(GET_COMPANY_DATA, handleGetCompanyData);
    yield takeLatest(GET_CONTACT_PERSON, handleGetContactPerson);
    yield takeLatest(GET_CONTACTS, handleGetContacts);
    yield takeLatest(GET_VERIFY_CODE, handleSendVerifyMail);
    yield takeLatest(GET_CHILDREN_CONTRACT, handleRequestGetChildrenContract);
    yield takeLatest(GET_CARD_CONTRACT, handleRequestGetCardContract);
    yield takeLatest(GET_VOUCHER_CONTRACT, handleRequestGetVoucherContract);
    yield takeLatest(GET_FORM_DATA, handleGetFormData);
    yield takeLatest(SAVE_FORM_DATA, handleSaveForm);
    yield takeLatest(GET_SERVICES, handleGetServices);
    yield takeLatest(GET_AFFILIATE, handleGetAffiliate);
    yield takeEvery(GET_MAINTENANCE_MODE, handleGetMaintenance);
}
